//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'Page',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  head() {
    const head = {
      script: [
        {
          defer: true,
          type: 'text/javascript',
          src: this.$config.paypalUrl,
        },
      ],
    }
    return head
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const hs =  urlParams.get("hs");
    const pqGuid =  urlParams.get("pqGuid");

    if (hs){
      this.setHs({hs});
      this.getCustomerBasedOnAccountNo({accountNo: hs});
    }
    if (pqGuid){
      this.setPQGuid({pqGuid})
    }
  },
  methods:{
    ...mapActions("customer",["getCustomerBasedOnAccountNo"]),
    ...mapMutations("frontend/urlParamStore",["setPQGuid","setHs"])
  },

}
